import { Link } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import useEnvVars from "../../customHooks/useEnvVars";
import useAccessToken from "../../customHooks/useAccessToken";

const mockExcelData = () => {
  const sampleData = new Uint8Array([0x50, 0x4b, 0x03, 0x04]);
  return new Blob([sampleData], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
};

const DownloadReportButtonRenderer = (props) => {
  const envVars = useEnvVars();
  const token = useAccessToken().getToken();
  const comparisonId = props.data?.comparison_id || props.value;
  const fileName = props.data?.report_name || "Download report";

  const handleClick = async () => {
    try {
      if (comparisonId) {
        let blob;
        if (envVars.REACT_APP_USE_MOCKED_BACKEND === "true") {
          blob = mockExcelData();
        } else {
          const response = await fetch(
            `${envVars.REACT_APP_BACKEND_URL}api/report-by-comparisonid?comparison_id=${comparisonId}`,
            {
              headers: {
                Authorization: `Bearer ${await token}`,
              },
            }
          );
          if (!response.ok) {
            throw new Error(`Error ${response.status}`);
          }
          blob = await response.blob();
        }
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        const fileName = props.data?.report_name;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      }
    } catch (error) {
      console.error("Error downloading report:", error);
    }
  };

  if (!props.data?.report_name) return null;

  return (
    <>
      <Link
        component="button"
        onClick={handleClick}
        sx={{
          textTransform: "none",
          paddingLeft: "0px",
          maxWidth: "100%",
          textAlign: "left",
          display: "block",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          cursor: "pointer",
          textDecoration: "none",
          "&:hover": {
            textDecoration: "underline",
          },
        }}
      >
        {fileName}
      </Link>
    </>
  );
};

DownloadReportButtonRenderer.propTypes = {
  value: PropTypes.string,
  data: PropTypes.shape({
    comparison_id: PropTypes.string,
    report_name: PropTypes.string,
  }),
};

export default DownloadReportButtonRenderer;
