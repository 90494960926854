import mockData from "./mocks/job-comparison-results-by-comparisonid";
import getEnvVars from "../utils/getEnvVars";

const { REACT_APP_USE_MOCKED_BACKEND, REACT_APP_BACKEND_URL } = getEnvVars();

const fetchFilesByComparisonId = async (comparisonId, token = "") => {
  if (REACT_APP_USE_MOCKED_BACKEND === "true") {
    const thisData = mockData.data;
    thisData.comparisons[0].review_notes = "This is a random string"
      .split("")
      .sort(() => Math.random() - 0.5)
      .join("");
    return new Promise((resolve) => setTimeout(() => resolve(thisData), 2500));
  }
  const response = await fetch(
    `${REACT_APP_BACKEND_URL}api/job-comparison-results-by-comparisonid?comparison_id=${comparisonId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  const data = await response.json();
  if (data.data.comparisons && Array.isArray(data.data.comparisons)) {
    data.data.comparisons = data.data.comparisons.sort((a, b) => {
      return a.results_id > b.results_id ? 1 : -1;
    });
  }
  return data.data;
};

export default fetchFilesByComparisonId;
