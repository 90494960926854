import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { loginRequest } from "../authConfig";
import useEnvVars from "./useEnvVars";

/**
 * A hook that checks if a user is authenticated and redirects to the login page
 * if not.
 *
 * @remarks
 * This hook is intended to be used in pages that require a user to be
 * authenticated. It will redirect the user to the login page if they are not
 * authenticated.
 *
 * The hook uses the {@link https://github.com/AzureAD/microsoft-authentication-library-for-js
 * | @azure/msal-react} library to check the authentication state of the user.
 *
 * If the environment variable `REACT_APP_USE_AUTH_BLOCK` is set to `"false"`,
 * the hook will not redirect the user to the login page.
 *
 * @returns {{
 * shouldBlockPage: boolean,
 * userData: import("@azure/msal-browser").AccountInfo | null
 * }}
 */
const useAuthenticatedPage = () => {
  const { instance, accounts, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate();
  const { REACT_APP_USE_AUTH_BLOCK, REACT_APP_REQUIRED_GROUP, ENVIRONMENT } = useEnvVars();

  useEffect(() => {
    if (REACT_APP_USE_AUTH_BLOCK !== "false" && !isAuthenticated && inProgress === "none") {
      navigate("/login");
      return;
    }

    if (isAuthenticated) {
      instance
        .acquireTokenSilent({
          ...loginRequest,
          scopes: ["User.Read", "GroupMember.Read.All"],
          account: accounts[0],
        })
        .then(async (response) => {
          try {
            // Fetch user's groups using Microsoft Graph API
            const graphResponse = await fetch("https://graph.microsoft.com/v1.0/me/memberOf", {
              headers: {
                Authorization: `Bearer ${response.accessToken}`,
                "Content-Type": "application/json",
              },
            });

            if (!graphResponse.ok) {
              throw new Error("Failed to fetch user groups");
            }

            const groupData = await graphResponse.json();
            const groups = groupData.value.map((group) => group.displayName);

            if (
              REACT_APP_REQUIRED_GROUP &&
              !groups.includes(REACT_APP_REQUIRED_GROUP) &&
              !(
                groups.includes("F_USER_Version1_PolicyCheckerDevelopers") &&
                REACT_APP_REQUIRED_GROUP === "R_AAD_SSO_PolicyCheckerDev"
              )
            ) {
              console.error("useAuthenticatedPage: User does not belong to required group", {
                requiredGroup: REACT_APP_REQUIRED_GROUP,
                userGroups: groups,
                environment: ENVIRONMENT,
              });
              throw new Error("User does not belong to the required group");
            }

            instance.setActiveAccount(accounts[0]);
          } catch (error) {
            console.error("useAuthenticatedPage: Error in group validation", {
              error: error.message,
              stack: error.stack,
            });
            navigate("/login-error");
          }
        })
        .catch((error) => {
          console.error("useAuthenticatedPage: Authentication error", {
            error: error.message,
            stack: error.stack,
          });
          navigate("/login-error");
        });
    }
  }, [navigate, isAuthenticated, REACT_APP_USE_AUTH_BLOCK, REACT_APP_REQUIRED_GROUP, inProgress]);

  const shouldBlockPage = REACT_APP_USE_AUTH_BLOCK !== "false" && !isAuthenticated && inProgress === "none";

  return {
    shouldBlockPage,
    userData: accounts?.[0] || null,
  };
};

export default useAuthenticatedPage;
