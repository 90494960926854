import PropTypes from "prop-types";

const ResultCellRenderer = (props) => {
  const getColor = (value, firstCheckpoint, secondCheckpoint) => {
    if (
      firstCheckpoint === null ||
      secondCheckpoint === null ||
      firstCheckpoint === undefined ||
      secondCheckpoint === undefined
    ) {
      return "#FF3E3E";
    }

    switch (value) {
      case true:
        return "#2FD92B"; // Green
      case false:
        return "#FF3E3E"; // Red
      default:
        return "gray";
    }
  };

  const value = props.data[props.colDef.field];
  const firstCheckpoint = props.data?.first_file_checkpoint_value;
  const secondCheckpoint = props.data?.second_file_checkpoint_value;

  const getDisplayText = () => {
    if (
      (firstCheckpoint === null || firstCheckpoint === undefined) &&
      (secondCheckpoint === null || secondCheckpoint === undefined)
    ) {
      return "Unvalidated";
    } else if (
      firstCheckpoint === null ||
      firstCheckpoint === undefined ||
      secondCheckpoint === null ||
      secondCheckpoint === undefined
    ) {
      return "Discrepancy";
    }
    return value ? "Match" : "Discrepancy";
  };

  return (
    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
      <span>{getDisplayText()}</span>
      <span
        style={{
          width: "13px",
          height: "13px",
          borderRadius: "50%",
          backgroundColor: getColor(value, firstCheckpoint, secondCheckpoint),
        }}
      ></span>
    </div>
  );
};

ResultCellRenderer.propTypes = {
  data: PropTypes.object.isRequired,
  colDef: PropTypes.shape({
    field: PropTypes.string.isRequired,
  }).isRequired,
};

export default ResultCellRenderer;
