import { useQuery } from "@tanstack/react-query";
import fetchHistoryChecks from "../services/get-historyChecks-api";
import useAccessToken from "./useAccessToken";

/**
 * Custom hook to fetch the history of policy checks.
 * Uses React Query for data fetching and caching.
 * Automatically includes the access token in the request.
 *
 * @returns {Object} Query result object with the following properties:
 * @returns {boolean} .isLoading - Whether the query is in progress
 * @returns {boolean} .isError - Whether the query encountered an error
 * @returns {Object} .error - Error object if the query failed
 * @returns {boolean} .isSuccess - Whether the query was successful
 * @returns {Object} .data - The history checks data if the query was successful
 */
const useHistoryChecks = () => {
  const accessTokenPromise = useAccessToken().getToken();
  let previousRefetch,
    refetch = 1000;

  return useQuery({
    queryKey: ["historyJobs"],
    queryFn: async () => {
      const accessToken = await accessTokenPromise;
      return fetchHistoryChecks(accessToken);
    },
    refetchInterval: () => {
      if (refetch < 32000) {
        const temp = refetch;
        refetch += previousRefetch;
        previousRefetch = temp;
      }
      return refetch;
    },
  });
};
export default useHistoryChecks;
