import React from "react";
import PropTypes from "prop-types";
import { MuiFileInput } from "mui-file-input";

const FileSelector = React.forwardRef((props, ref) => {
  const handleChange = (newFile) => {
    // Only accept pdf files
    if (newFile && newFile.type === "application/pdf") {
      props.onChange(newFile);
    }
  };

  const onDrop = (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    if (files.length > 0 && files[0].type === "application/pdf") {
      props.onChange(files[0]);
    }
  };

  const onDragOver = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  console.log("FileSelector", props);
  //
  return (
    <MuiFileInput
      {...props}
      ref={ref}
      name={props.name}
      value={props.value}
      onChange={handleChange}
      inputProps={{ accept: ".pdf", required: !props.value }}
      placeholder="Choose or drag a PDF file"
      getInputText={(value) => (value ? value.name : "")}
      onDragOver={onDragOver}
      onDrop={onDrop}
      dragProps={{
        sx: {
          "&.mui-file-input-drag-over": {
            backgroundColor: "action.hover",
            borderColor: "primary.main",
          },
        },
      }}
    />
  );
});

FileSelector.displayName = "FileSelector";

FileSelector.propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.object,
};

export default FileSelector;
