import mockData from "./mocks/jobs.js";
import getEnvVars from "../utils/getEnvVars";

const { REACT_APP_USE_MOCKED_BACKEND, REACT_APP_BACKEND_URL } = getEnvVars();

const fetchActiveChecks = async (token = "") => {
  console.log("fetchActiveChecks");
  if (REACT_APP_USE_MOCKED_BACKEND === "true") {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(mockData.data);
      }, 1000);
    });
  }
  const response = await (
    await fetch(`${REACT_APP_BACKEND_URL}api/jobs?status=ACTIVE`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  ).json();
  /**
   response: {
     status: "success",
     message: "Jobs fetched successfully.",
     data: [...]
     status_code: 200
   }
   */
  return response.data;
};
export default fetchActiveChecks;
