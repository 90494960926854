/* global process */
const getEnvVars = () => {
  return {
    REACT_APP_BACKEND_URL: process.env.REACT_APP_BACKEND_URL,
    REACT_APP_USE_MOCKED_BACKEND: process.env.REACT_APP_USE_MOCKED_BACKEND,
    REACT_APP_AUTH_CLIENT_ID: process.env.REACT_APP_AUTH_CLIENT_ID,
    REACT_APP_AUTH_AUTHORITY: process.env.REACT_APP_AUTH_AUTHORITY,
    REACT_APP_AUTH_REDIRECT_URI: process.env.REACT_APP_AUTH_REDIRECT_URI,
    REACT_APP_AUTH_GRAPHME_ENDPOINT: process.env.REACT_APP_AUTH_GRAPHME_ENDPOINT,
    REACT_APP_USE_AUTH_BLOCK: process.env.REACT_APP_USE_AUTH_BLOCK,
    REACT_APP_REQUIRED_GROUP: process.env.REACT_APP_REQUIRED_GROUP,
    ENVIRONMENT: process.env.NODE_ENV,
    REACT_APP_USE_HIGHLIGHTING: process.env.REACT_APP_USE_HIGHLIGHTING || false,
  };
};

export default getEnvVars;
