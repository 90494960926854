import React, { useEffect, useMemo, useState } from "react";
import { Viewer, SpecialZoomLevel, createStore, ProgressBar } from "@react-pdf-viewer/core";
import { searchPlugin } from "@react-pdf-viewer/search";
import { pageNavigationPlugin } from "@react-pdf-viewer/page-navigation";
import { Box, CircularProgress } from "@mui/material";
import PropTypes from "prop-types";
import "@react-pdf-viewer/search/lib/styles/index.css";
import { Worker } from "@react-pdf-viewer/core";
import { tokenizeSearchTerm } from "../utils/pdfUtils";

/**
 * Converts a base64 encoded PDF string to a Blob object.
 * @param {string} data - The base64 encoded PDF data.
 * @returns {Blob} A Blob object containing the PDF data.
 */
const base64toBlob = (data) => {
  const bytes = atob(data);
  let length = (bytes || "").length;
  let out = new Uint8Array(length);
  while (length--) {
    out[length] = bytes.charCodeAt(length);
  }
  return new Blob([out], { type: "application/pdf" });
};

const CoordinateHighlights = React.memo(({ pageIndex, coordinates, isMatch }) => {
  if (!coordinates || coordinates.length === 0) return null;

  return (
    <>
      {coordinates.map((area, index) => (
        <div
          className="coordinate-highlight"
          key={`${pageIndex}-${index}`}
          data-index={index}
          style={{
            position: "absolute",
            left: `${(area.x0 * 100) / 612}%`,
            top: `${(area.y0 * 100) / 792}%`,
            width: `${((area.x1 - area.x0) * 100) / 612}%`,
            height: `${((area.y1 - area.y0) * 100) / 792}%`,
            border: `2px solid ${isMatch ? "rgba(0, 255, 0, 0.5)" : "rgba(255,0,0,0.5)"}`,
            pointerEvents: "none",
          }}
        ></div>
      ))}
    </>
  );
});
CoordinateHighlights.displayName = "CoordinateHighlights";
CoordinateHighlights.propTypes = {
  pageIndex: PropTypes.number,
  coordinates: PropTypes.array,
  isMatch: PropTypes.bool,
};

const CoordinateMarkerPlugin = (page, coordinates = [], isMatch = true) => {
  const store = useMemo(
    () =>
      createStore({
        coordinates,
        pageIndex: page,
        isMatch,
        scale: 1,
        rotation: 0,
      }),
    []
  );

  const jumpToCoordinate = (page, coordinate) => {
    const jumpToDestination = store.get("jumpToDestination");
    if (!jumpToDestination) {
      return;
    }
    jumpToDestination({
      pageIndex: page,
      bottomOffset: (_, viewportHeight) => viewportHeight - coordinate.y0,
      leftOffset: () => coordinate.x0,
    });
  };

  return {
    install: (pluginFunctions) => {
      store.update("jumpToDestination", pluginFunctions.jumpToDestination);
    },
    onPageRender: (e) => {
      store.update("scale", e.scale);
      store.update("rotation", e.rotation);
    },
    renderPageLayer: (props) => {
      if (props.pageIndex !== store.get("pageIndex")) {
        return <></>;
      }
      return (
        <CoordinateHighlights
          pageIndex={props.pageIndex}
          coordinates={store.get("coordinates")}
          isMatch={store.get("isMatch")}
        />
      );
    },
    setCoordinates: (page, coordinates, isMatch) => {
      console.log("setCoordinates", page, coordinates, isMatch);
      if (page < 0 || !coordinates) {
        return;
      }
      store.update("coordinates", coordinates);
      store.update("pageIndex", page);
      store.update("isMatch", isMatch);
      if (coordinates && coordinates.length > 0) {
        jumpToCoordinate(page, coordinates[0]);
      }
    },
    jumpToCoordinate,
  };
};

/**
 * A React component that renders a PDF viewer with search, navigation, and highlighting capabilities.
 * This component uses the @react-pdf-viewer library to provide a rich PDF viewing experience.
 *
 * Features:
 * - Automatic page jumping to search results
 *
 * @component
 * @param {Object} props
 * @param {string} props.pdfUrl - Base64 encoded PDF data to be displayed
 * @param {number} [props.targetPages] - Specific page number to navigate to (0-based index)
 * @param {Object} [props.coordinates] - x0, y0, x1, y1 coordinates for search boxes
 *
 * @example
 * <PdfViewer
 *   pdfUrl="base64EncodedPdfData"
 *   targetPages={2}
 *   zoomLevel="width"
 * />
 */
const PdfViewer = ({ pdfUrl, coordinates, targetPages, isMatch, searchTerm }) => {
  const coordinatePluginInstance = CoordinateMarkerPlugin(targetPages, coordinates, isMatch);
  const [currentTokenizedSearch, setCurrentTokenizedSearch] = useState(tokenizeSearchTerm(searchTerm));
  const [usingUntokenizedSearch, setUseTokenizedSearch] = useState(false);
  const searchPluginInstance = searchPlugin({
    enableShortcuts: false,
    currentTokenizedSearch: currentTokenizedSearch,
    renderHighlights: React.useCallback(
      (renderProps) => (
        <>
          {renderProps.highlightAreas.map((area, index) => (
            <div
              className="rpv-search__highlight"
              key={`${area.pageIndex}-${index}`}
              data-index={index}
              style={{
                ...renderProps.getCssProperties(area),
                position: "absolute",
                backgroundColor: "rgba(0,0,0,0)",
                border: `2px solid ${isMatch ? "rgba(0, 255, 0, 0.5)" : "rgba(255,0,0,0.5)"}`,
              }}
            ></div>
          ))}
        </>
      ),
      [isMatch]
    ),
  });
  const paginationPluginInstance = pageNavigationPlugin();
  useEffect(() => {
    searchPluginInstance.clearHighlights();
    if (searchTerm && !coordinates) {
      setUseTokenizedSearch(false);
      setCurrentTokenizedSearch([searchTerm]);
    } else if (!coordinates) {
      paginationPluginInstance.jumpToPage(targetPages);
    }
    searchPluginInstance.setTargetPages((tp) => (tp || {}).pageIndex === targetPages);
  }, [searchTerm, targetPages, coordinates]);
  useEffect(() => {
    if (!coordinates && typeof targetPages === "number") {
      const searchTightness = 1.5;
      searchPluginInstance.highlight(currentTokenizedSearch).then((matches) => {
        if (usingUntokenizedSearch && matches.length === 0) {
          setCurrentTokenizedSearch(tokenizeSearchTerm(searchTerm));
          setUseTokenizedSearch(true);
        } else if (
          matches.length > currentTokenizedSearch.length * searchTightness &&
          currentTokenizedSearch.length > 1
        ) {
          // Find the shortest search term
          const matchesMap = {};
          currentTokenizedSearch.forEach((kw) => {
            matchesMap[kw] = 0;
            matches.forEach((match) => {
              if (kw.search(match.keyword) !== -1) {
                matchesMap[kw]++;
              }
            });
          });
          let mostMatchesIndex = 0;
          let mostMatches = matchesMap[currentTokenizedSearch[0]];
          currentTokenizedSearch.forEach((kw, i) => {
            if (matchesMap[kw] > mostMatches) {
              mostMatchesIndex = i;
            }
          });
          console.log("mostMatchesIndex", mostMatchesIndex);
          // Remove the shortest term and update the search
          const newTokenizedSearch = currentTokenizedSearch.filter((_, index) => index !== mostMatchesIndex);
          if (newTokenizedSearch.length < currentTokenizedSearch.length && newTokenizedSearch.length > 0) {
            console.log("Shorter search term found and highlighted", newTokenizedSearch, currentTokenizedSearch);
            setCurrentTokenizedSearch(newTokenizedSearch);
          }
        } else if (matches.length === 0) {
          const newTokenizedSearch = currentTokenizedSearch.map((i) => tokenizeSearchTerm(i)).flat();
          if (currentTokenizedSearch.length !== newTokenizedSearch.length) {
            setCurrentTokenizedSearch(newTokenizedSearch);
          } else {
            paginationPluginInstance.jumpToPage(targetPages);
          }
        }
      });
    }
  }, [currentTokenizedSearch, coordinates]);

  const url = useMemo(() => {
    const blob = base64toBlob(pdfUrl);
    return URL.createObjectURL(blob);
  }, [pdfUrl]);

  useEffect(function updateCoordinates() {
    coordinatePluginInstance.setCoordinates(targetPages, coordinates, isMatch);
  });

  if (!pdfUrl) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <CircularProgress data-testid="loading-indicator" />
      </Box>
    );
  }

  const plugins = [coordinatePluginInstance, paginationPluginInstance, searchPluginInstance];
  return (
    <Worker workerUrl={`//cdnjs.cloudflare.com/ajax/libs/pdf.js/3.11.174/pdf.worker.min.js`}>
      <Viewer
        fileUrl={url}
        defaultScale={SpecialZoomLevel.PageWidth}
        plugins={plugins}
        initialPage={targetPages || 1}
        renderLoader={(percentage) => (
          <div style={{ width: "240px" }}>
            <ProgressBar progress={Math.round(percentage)} />
          </div>
        )}
      />
    </Worker>
  );
};

PdfViewer.propTypes = {
  pdfUrl: PropTypes.string.isRequired,
  searchTerm: PropTypes.string,
  coordinates: PropTypes.arrayOf(
    PropTypes.shape({
      x0: PropTypes.number.isRequired,
      x1: PropTypes.number.isRequired,
      y0: PropTypes.number.isRequired,
      y1: PropTypes.number.isRequired,
    })
  ),
  targetPages: PropTypes.number,
  isMatch: PropTypes.bool,
};

PdfViewer.displayName = "PdfViewer";

export default PdfViewer;
