/**
 * Splits a date range string into individual date tokens.
 * @param {string} text - The text that might contain a date range.
 * @returns {string[]} Array of individual date strings or empty array if no date range found.
 */
export const splitDateRange = (text) => {
  const dateRangeRegex = /(\d{2}\/\d{2}\/\d{4})-(\d{2}\/\d{2}\/\d{4})/;
  const match = text.match(dateRangeRegex);
  if (match) {
    return [match[1], match[2]];
  }
  return [];
};

/**
 * Tokenizes a search term into individual words.
 * @param {string} searchTerm - The search term to tokenize.
 * @returns {string[]} An array of strings representing individual words and dates in the search term.
 */
export const tokenizeSearchTerm = (searchTerm) => {
  if (!searchTerm) {
    return [];
  }

  const words = searchTerm
    .split(/\s+/)
    .map((word) => word.replace(/^,+|,+$/g, ""))
    .map((word) => word.replace(/^\\"+|\\"+$/g, ""))
    .map((word) => word.replace(/^[£$€]\s*|\s*[£$€]$/g, "")) // Remove currency symbols from start or end
    .filter(Boolean);

  const tokens = [];
  for (const word of words) {
    const dateTokens = splitDateRange(word);
    if (dateTokens.length > 0) {
      tokens.push(...dateTokens);
    } else {
      tokens.push(word);
    }
  }

  // Filter the words that are a subset of other words
  return tokens.filter((word) => !tokens.some((otherWord) => otherWord !== word && otherWord.includes(word)));
};
