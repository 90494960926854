import { useQuery } from "@tanstack/react-query";
import useAccessToken from "./useAccessToken";
import fetchActiveChecks from "../services/get-activeChecks-api";

/**
 * Custom React hook that fetches active checks/jobs using React Query.
 * Uses React Query for data fetching and caching.
 * Automatically includes the access token in the request.
 *
 * @returns {Object} Query result object with the following properties:
 * @returns {boolean} .isLoading - Whether the query is in progress
 * @returns {boolean} .isError - Whether the query encountered an error
 * @returns {Object} .error - Error object if the query failed
 * @returns {boolean} .isSuccess - Whether the query was successful
 * @returns {Object} .data - The active checks data if the query was successful
 * @returns {Function} .refetch - Function to manually trigger a refetch
 */
const useActiveChecks = () => {
  const accessTokenPromise = useAccessToken().getToken();
  let previousRefetch = 1000;
  let refetch = 1000;
  return useQuery({
    queryKey: ["activeJobs"],
    queryFn: async () => {
      const accessToken = await accessTokenPromise;
      return fetchActiveChecks(accessToken);
    },
    refetchInterval: () => {
      if (refetch < 32000) {
        const temp = refetch;
        refetch += previousRefetch;
        previousRefetch = temp;
      }
      return refetch;
    },
  });
};

export default useActiveChecks;
